import React, { useState } from 'react';
import MedicalDashboard from './dashboard';
import userData from './usuarios.json';

function PatientList() {
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false);

  const handleUserClick = (index) => {
    setSelectedUserIndex(index);
    setShowDashboard(true);
  };

  const handleReturnToList = () => {
    setSelectedUserIndex(null);
    setShowDashboard(false);
  };

  return (
    <div className="container bg-white text-dark shadow p-4">
      {showDashboard ? (
        <>
          <button className="btn btn-primary mb-4" onClick={handleReturnToList} style={{ backgroundColor: 'white', border: '1px solid blue', color: 'black', textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>Volver a la lista de pacientes</button>
          {selectedUserIndex !== null && <MedicalDashboard userIndex={selectedUserIndex} />}
        </>
      ) : (
        <>
          <h2 className="mt-4" style={{ color: 'black', textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>Lista de Pacientes</h2>
          <div className="row row-cols-1 row-cols-md-3 g-4">
            {userData.usuarios.map((user, index) => (
              <div key={index} className="col">
                <div className="card border shadow" style={{ height: '100%', width: '100%' }}>
                  <div className="card-body d-flex flex-column justify-content-between p-3" onClick={() => handleUserClick(index)} style={{ cursor: 'pointer', color: 'black', textShadow: '2px 2px 4px rgba(0,0,0,0.5)', height: '100%' }}>
                    <h5 className="card-title">{user.nombre}</h5>
                    <p className="card-text">Edad: {user.edad}</p>
                    <p className="card-text">Género: {user.genero}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default PatientList;
