import React from 'react';
import Header from '../components/header';
import { Container, Row, Col } from 'react-bootstrap';
import HeroSection from '../components/HeroSection';
import CustomCard from '../components/customCard';
import image from '../images/vertical1.jpg';
import image2 from '../images/horizontal 2.jpg';
import ColumnCard from '../components/columncard';
import FeatureCard from '../components/featureCard';
import CustomCardReverse from '../components/customCardReverse';
import HorizontalImageCard from '../components/horizontalcard';
import image3 from '../images/vertical2.jpg';
import image1 from '../images/horizontal 1.jpg';
import hubprovi from '../images/hubprovidencia.jpg';
import microsoft from '../images/Microsoft-for-Startups.jpg';
import aws from '../images/aws.jpg';
import eiva from '../images/EIVA-Head.png';
import '../styles/landing.css';
import ColumnCardbottom from '../components/columncardbottom';
import Footer from '../components/footer';
import ParticlesComponent from '../components/particles';

function LandingPage({ setIsAuthenticated }) {
  return (
    <div className="page"> {/* Agrega la clase 'page' al contenedor principal */}   
      <div className="particles-container">
        <ParticlesComponent id="tsparticles" />
      </div>
      <Container>
        <Row>
          <Col>
          <Header setIsAuthenticated={setIsAuthenticated} />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
          {<HeroSection />}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <CustomCard
              title="Mi Salud Portable"
              text="Innovamos en la forma de hacer medicina para asegurar que la tecnología permita que todas las personas tengan acceso a la salud y puedan beneficiarse de avances médicos de vanguardia e integrales"
              imageSrc={image}
              size="large" // Tamaño pequeño
            />
          </Col>
        </Row>
      </Container>

      
      {/* Carrusel */}
      <Container>
        <Row>
          <Col>
            <ColumnCard
              title="Gracias a nuestras integraciones, logramos la portabilidad de la información de salud."
              text="Nuestro ecosistema modular nos permite ofrecer soluciones ágiles y versátiles,
              implementables según las necesidades específicas de cada cliente.
              Utilizando inteligencia artificial, creamos recordatorios y recomendaciones de salud
              personalizadas para los usuarios."
              imageSrc={image2}
              cardSize="large" // Puedes usar 'small' o 'large' para el tamaño de la tarjeta
              imageSize="large" // Puedes usar 'small' o 'large' para el tamaño de la imagen
            />
          </Col>
        </Row>
      </Container>


      {/* Texto */}
      <Container>
        <Row>
          <Col>
            <FeatureCard icon="naves" text="Con esto logramos que se disminuyen los tiempos y listas de espera" />
            <FeatureCard icon="sol" text="Aumenta la eficiencia en la atención y en la gestión de los recursos" />
            <FeatureCard icon="estrella" text="Tienes información siempre al día de la salud de los usuarios" />
            <FeatureCard icon="doctor" text="A través de IA tener recordatorios de citas médicas y toma de medicamentos y recomendaciones de salud personalizadas" />
            {/* Agrega más tarjetas según sea necesario */}
          </Col>
        </Row>
      </Container>

      {/* Imágenes */}
      <Container>
        <Row>
          <Col>
            <CustomCard
              title="MedWise"
              text="Ofrecemos un ecosistema
              modular de salud completo que
              incluye soluciones innovadoras y
              tecnología avanzada, adaptadas
              individualmente para permitir
              que tanto las personas como los
              profesionales de la salud
              accedan a ellas desde cualquier
              lugar y en cualquier momento.
              Nuestro objetivo es abordar las
              deficiencias estructurales del
              sistema sanitario mediante una
              combinación de atención
              personalizada, innovación
              tecnológica y excelencia médica"
              imageSrc={image}
              size="large" // Tamaño pequeño
            />
            <CustomCardReverse
              title="SaaS"
              text="Como expertos en salud, ofrecemos un
              software como servicio (SaaS)
              especializado en la digitalización de
              procesos en centros de salud, nuestra
              tecnología de vanguardia estandariza
              los procesos garantizando su
              interoperabilidad con múltiples
              sistemas informáticos de prestadores
              de salud, produciendo así un flujo de
              información eficiente en el journey del
              usuario"
              imageSrc={image3} // Ajusta la ruta de la imagen
              size="large" // Opcional: 'small' o 'large' para el tamaño de la tarjeta
            />
            <CustomCard
              title="Ficha Clinica Electronica"
              text="En MEDWISE, simplificamos los
              diagnósticos de especialistas médicos
              mediante una plataforma web que
              integra la Ficha Clínica Electrónica,
              así como la transmisión sincrónica de
              datos, imágenes, audio y vídeo"
              imageSrc={image}
              size="large" // Tamaño pequeño
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <HorizontalImageCard images={[{ src: hubprovi, alt: 'Descripción de la imagen 1' },{ src: microsoft, alt: 'Descripción de la imagen 1' }, { src: aws, alt: 'Descripción de la imagen 2' }, { src: eiva, alt: 'Descripción de la imagen 2' }]} />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <ColumnCardbottom
              title="¡Prueba nuestro demo ahora!"
              text="Nuestro amplio catálogo de
              especialidades está diseñado para
              mejorar la calidad de vida del paciente
              desde su hogar, ofreciendo un método
              seguro, confiable, rápido y eficaz. De
              esta manera, MEDWISE permite a los
              especialistas dar seguimiento regular a
              sus consultas"
              imageSrc={image2}
              cardSize="large" // Puedes usar 'small' o 'large' para el tamaño de la tarjeta
              imageSize="large" // Puedes usar 'small' o 'large' para el tamaño de la imagen
              setIsAuthenticated={setIsAuthenticated}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <Footer/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LandingPage;
