import React from 'react';
import Header from '../components/header';
import MedicalDashboard from './../components/dashboard';

// En el render de tu componente principal

function FichaClinicaPage() {
  return (
    <div className="ficha-clinica-page">
      <Header hideButtons={true} /> {/* Oculta los botones en esta página */}
      <div className="container">
        <MedicalDashboard userIndex={0} />
      </div>
    </div>
  );
}

export default FichaClinicaPage;
