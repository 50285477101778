import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import HorasMedicas from './HorasMedicas'; // Asegúrate de que la ruta sea correcta

const CalendarComponent = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateClick = (value) => {
    setSelectedDate(value);
  };

  return (
    <div>
      <Calendar onClickDay={handleDateClick} />
      {selectedDate && (
        <div>
          <h3>Horas Médicas para {selectedDate.toLocaleDateString()}</h3>
          <HorasMedicas date={selectedDate.toISOString().split('T')[0]} />
        </div>
      )}
    </div>
  );
};

export default CalendarComponent;
