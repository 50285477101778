// Importaciones necesarias
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ChartComponent from './Chart';
import PieChart from './PieChart';
import BarChart from './BarChart';
import 'bootstrap/dist/css/bootstrap.min.css';

// Datos y opciones actualizados para el gráfico de línea
const lineData = {
  labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
  datasets: [
    {
      label: 'Número de personas nuevas con ficha médica',
      data: [45, 60, 75, 80, 70, 90],
      borderColor: 'rgba(75,192,192,1)',
      backgroundColor: 'rgba(75,192,192,0.2)',
    },
  ],
};

const lineOptions = {
  responsive: true,
  maintainAspectRatio: false,
};

// Datos y opciones actualizados para el gráfico de pastel
const pieData = {
  labels: ['FONASA', 'ISAPRE'],
  datasets: [
    {
      label: 'Previsión de salud de los pacientes',
      data: [200, 150],
      backgroundColor: ['#36A2EB', '#FF6384'],
      hoverBackgroundColor: ['#36A2EB', '#FF6384'],
    },
  ],
};

const pieOptions = {
  responsive: true,
  maintainAspectRatio: false,
};

// Datos y opciones actualizados para el gráfico de barra
const barData = {
  labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
  datasets: [
    {
      label: 'Cantidad de personas en lista de espera',
      data: [30, 25, 40, 35, 50, 45],
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 1,
    },
  ],
};

const barOptions = {
  responsive: true,
  maintainAspectRatio: false,
};

// Componente Dashboard
const Estadisticas = () => {
  return (
    <Container fluid>
      <Row className="mb-4">
        <Col lg={6} md={12}>
          <div style={{ height: '400px' }}>
            <ChartComponent data={lineData} options={lineOptions} />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div style={{ height: '400px' }}>
            <PieChart data={pieData} options={pieOptions} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12}>
          <div style={{ height: '400px', marginBottom: '40px' }}>
            <BarChart data={barData} options={barOptions} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Estadisticas;
