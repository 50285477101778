import React from 'react';
import { Chart as ChartJS, LineElement, LinearScale, CategoryScale, PointElement, LineController, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

// Registrar los componentes de Chart.js que se van a utilizar
ChartJS.register(
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend
);

const ChartComponent = ({ data, options }) => {
  return <Line data={data} options={options} />;
};

export default ChartComponent;
