import React from 'react';
import { Card, Image } from 'react-bootstrap';

function HorizontalImageCard({ images }) {
  return (
    <div className="d-flex justify-content-center my-5">
      <Card className="rounded-3" style={{ backgroundColor: 'white', border: 'none', width: '80%' }}> {/* Ajusta el ancho de la tarjeta */}
        <div className="d-flex overflow-auto align-items-center p-3"> {/* Añade padding para más espacio interno */}
          {images.map((image, index) => (
            <div key={index} className="me-3 mb-2"> {/* Ajusta el margen derecho y margen inferior */}
              <Image src={image.src} alt={image.alt} fluid />
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
}

export default HorizontalImageCard;
