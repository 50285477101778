import React from 'react';
import Header from '../components/header';
import PatientList from '../components/patientlist';
import '../styles/adminpage.css'; // Importa los estilos específicos de la página

function AdminPage() {
  return (
    <div className="admin-page bg-white text-primary">
      <Header hideButtons={true} />
      <div className="container-fluid"> {/* Utiliza un contenedor fluido */}
        <PatientList />
      </div>
    </div>
  );
}

export default AdminPage;
