import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import footerImage from '../images/3IE_Corfo.png'; // Importa la imagen del footer

function Footer() {
  return (
    <footer className="bg-dark text-light py-4">
      <Container>
        <Row className="align-items-center"> {/* Alinea los elementos verticalmente */}
          <Col md={6}>
            <h5>Información de contacto</h5>
            <p>Dirección: 123 Calle Principal, Ciudad</p>
            <p>Teléfono: +1234567890</p>
            <p>Email: info@example.com</p>
          </Col>
          <Col md={6} className="text-center"> {/* Alinea al centro en todas las pantallas */}
            <img src={footerImage} alt="Footer" className="img-fluid" style={{ maxHeight: '100px' }} /> {/* Ajusta el tamaño de la imagen */}
          </Col>
        </Row>
        <hr className="bg-light" />
        <Row>
          <Col>
            <p className="text-center">&copy; 2024 Todos los derechos reservados</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
