import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { GiSpaceship, GiSun, GiStarfighter, GiHealthNormal } from 'react-icons/gi'; // Importa los iconos necesarios
import '../styles/featureCard.css'

function FeatureCard({ icon, text }) {
  // Función para determinar el icono correspondiente según la propiedad 'icon'
  const getIcon = () => {
    switch (icon) {
      case 'naves':
        return <GiSpaceship />;
      case 'sol':
        return <GiSun />;
      case 'estrella':
        return <GiStarfighter />;
      case 'doctor':
        return <GiHealthNormal />;
      default:
        return null;
    }
  };

  return (
    <div className="feature-card-container my-5"> {/* Añade margen alrededor del contenedor */}
      <Card className="rounded-3 shadow-sm border card"> {/* Ancho fijo para todos los FeatureCard */}
        <Card.Body>
          <Row className="align-items-center" style={{ marginBottom: '-10px' }}>
            <Col xs={2} className="text-center">
              <div className="icon" style={{ marginRight: '10px' }}>{getIcon()}</div>
            </Col>
            <Col xs={9}>
              <Card.Text className="ml-2">{text}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default FeatureCard;
