import React from 'react';
import '../styles/Siderbar.css';

const Sidebar = ({ setSelectedComponent }) => {
  return (
    <div className="sidebar">
      <ul>
        <li onClick={() => setSelectedComponent('Estadisticas')}>Estadisticas</li>
        <li onClick={() => setSelectedComponent('Horas Medicas')}>Horas Medicas</li>
        <li onClick={() => setSelectedComponent('Pacientes')}>Pacientes</li>
      </ul>
    </div>
  );
};

export default Sidebar;
