import React, { useState } from 'react';
import '../styles/predictionBox.css';
import predictionData from './predicciones.json'; // Archivo JSON con las predicciones
import userImage from '../images/Cuerpo_Humano.jpg'; // Ruta de la imagen local
import 'bootstrap/dist/css/bootstrap.min.css'; // Asegúrate de tener Bootstrap instalado e importado

function PredictionBox({ userIndex }) {
  const [showPrediction, setShowPrediction] = useState(false);
  const [displayedText, setDisplayedText] = useState('');
  const [showAlert, setShowAlert] = useState(false); // Estado para controlar la visibilidad de la alerta
  const userPrediction = predictionData.predicciones[userIndex];

  const handleConsult = () => {
    if (showPrediction) {
      setShowAlert(true); // Mostrar la alerta si ya se ha mostrado la predicción
      return;
    }
    setShowPrediction(true);
    setShowAlert(false); // Ocultar la alerta cuando se empieza a mostrar la predicción
    setDisplayedText('');
    let index = 0;
    const intervalId = setInterval(() => {
      if (index < userPrediction.length) {
        setDisplayedText((prev) => prev + userPrediction.charAt(index));
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 50);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <img 
            src={userImage} 
            alt="Imagen de usuario" 
            className="img-fluid responsive-image"
          />
        </div>
        <div className="col-lg-6 col-md-12 d-flex flex-column align-items-center justify-content-center">
          <div className="box p-3 mb-3 shadow">
            {showPrediction ? (
              <p className="prediction-text">{displayedText}</p>
            ) : (
              <p className="prediction-placeholder">Presione "Consultar" para ver la predicción</p>
            )}
          </div>
          {showAlert && (
            <div className="alert alert-info" role="alert">
              Para hacer una nueva consulta debes añadir o actualizar información médica
            </div>
          )}
          <button 
            className="btn btn-primary mt-3" 
            onClick={handleConsult}
          >
            Consultar
          </button>
        </div>
      </div>
    </div>
  );
}

export default PredictionBox;
