import React from 'react';
import userData from './usuarios.json'; // Importa el archivo JSON con los datos de usuario
import '../styles/dashboard.css';
import PredictionBox from './PredictionBox';

function MedicalDashboard({ userIndex }) {
  // Buscar el usuario por su índice en el archivo JSON
  const user = userData.usuarios[userIndex];

  if (!user) {
    return <div>No se encontró el usuario</div>;
  }

  return (
    <div className="container-fluid text-black">
      <div className="row justify-content-center mt-4">
        <div className="col">
          <h2 className="mb-4 text-center" style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>Ficha Médica - {user.nombre}</h2>
          <div className="dashboard-content"> {/* Contenedor adicional para centrar */}
            <div className="card border shadow">
              <div className="card-body">
                <h3 className="card-title text-center">Información del Paciente</h3>
                <p className="card-text text-center"><strong>Nombre:</strong> {user.nombre}</p>
                <p className="card-text text-center"><strong>Edad:</strong> {user.edad}</p>
                <p className="card-text text-center"><strong>Género:</strong> {user.genero}</p>
                {user.genero.toLowerCase() === 'femenino' && (
                  <p className="card-text text-center"><strong>Cantidad de hijos:</strong> {user.cantidad_hijos || 'N/A'}</p>
                )}
                <p className="card-text text-center"><strong>Grupo sanguíneo:</strong> {user.grupo_sanguineo}</p>
                <p className="card-text text-center"><strong>Alergias:</strong> {user.alergias}</p>
                <p className="card-text text-center"><strong>Enfermedades Crónicas:</strong> {user.enfermedades}</p>
                <p className="card-text text-center"><strong>Medicamentos actuales:</strong> {user.medicamentos}</p>
                <p className="card-text text-center"><strong>Dosis:</strong> {user.dosis}</p>
                <p className="card-text text-center"><strong>En caso de emergencia avisar a:</strong> {user.contacto_emergencia}</p>
                <p className="card-text text-center"><strong>Teléfono:</strong> {user.telefono_contacto}</p>
                <p className="card-text text-center"><strong>Observaciones:</strong> {user.observaciones}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-4">
        <div className="col">
          <div className="dashboard-content"> {/* Contenedor adicional para centrar */}
            <div className="card border shadow">
              <div className="card-body">
                <h3 className="card-title text-center">Historial Médico</h3>
                <ul className="list-group list-group-flush">
                  {user.historial_medico.map((record, index) => (
                    <li key={index} className="list-group-item">
                      <h4 className="card-title">{record.titulo}</h4>
                      <p className="card-text text-center">{record.detalles}</p>
                      {record.archivo && (
                        <a href={record.archivo} target="_blank" rel="noopener noreferrer" className="btn btn-primary d-block mx-auto">Ver archivo adjunto</a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-4">
        <div className="col">
          <div className="dashboard-content"> {/* Contenedor adicional para centrar */}
            <div className="card border shadow">
              <div className="card-body">
                <h3 className="card-title text-center">Notas Adicionales</h3>
                <p className="card-text text-center">{user.notas_adicionales}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-4">
        <div className="col">
          <div className="dashboard-content"> {/* Contenedor adicional para centrar */}
            <PredictionBox userIndex={userIndex} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedicalDashboard;
