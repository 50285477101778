import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/header.css';
import LoginModal from './loginModal';
import SecondUserLoginModal from './secondmodal';
import ThreeLoginModal from './threemodal';

function Header({ setIsAuthenticated }) {
  const [showModal, setShowModal] = useState(false);
  const [showSecondUserModal, setShowSecondUserModal] = useState(false);
  const [showDataAnalysisModal, setShowDataAnalysisModal] = useState(false);

  const location = useLocation();
  const hideButtons = location.pathname !== '/';

  const handleVerFichaClinica = () => {
    setShowModal(true);
  };

  const handleAdminSalud = () => {
    setShowSecondUserModal(true);
  };

  const handleDataAnalysis = () => {
    setShowDataAnalysisModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseSecondUserModal = () => {
    setShowSecondUserModal(false);
  };

  const handleCloseDataAnalysisModal = () => {
    setShowDataAnalysisModal(false);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light custom-navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <span className="text-primary" style={{ color: '#122DF0' }}>MI SALUD PORTABLE</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <div className="navbar-nav">
              {!hideButtons && (
                <>
                  <button className="btn btn-primary me-lg-2 me-md-0 mb-2 mb-lg-0" style={{ backgroundColor: '#122DF0' }} onClick={handleVerFichaClinica}>Ver Ficha Clínica</button>
                  <button className="btn btn-primary me-lg-2 me-md-0 mb-2 mb-lg-0" style={{ backgroundColor: '#122DF0' }} onClick={handleAdminSalud}>Admin Salud</button>
                  <button className="btn btn-primary" style={{ backgroundColor: '#122DF0' }} onClick={handleDataAnalysis}>Data Analysis</button>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
      <LoginModal show={showModal} onHide={handleCloseModal} setIsAuthenticated={setIsAuthenticated} />
      <SecondUserLoginModal show={showSecondUserModal} onHide={handleCloseSecondUserModal} setIsAuthenticated={setIsAuthenticated} />
      <ThreeLoginModal show={showDataAnalysisModal} onHide={handleCloseDataAnalysisModal} setIsAuthenticated={setIsAuthenticated} />
    </>
  );
}

export default Header;
