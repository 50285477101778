import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function SecondUserLoginModal({ show, onHide, setIsAuthenticated }) {
  const [rut, setRut] = useState('');
  const [clave, setClave] = useState('');
  const [rutError, setRutError] = useState('');
  const [claveError, setClaveError] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    // Validar el RUT y la clave
    //if (rut.match(/^\d{7,8}-[\d|kK]$/) && clave.match(/^\d{4}$/)) {
    setIsAuthenticated(true); // Actualizar el estado de autenticación
    onHide(); // Cerrar el modal
    navigate('/admin-page'); // Redirigir a la página de administrador
  
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Iniciar Sesión</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="rut">
            <Form.Label>RUT</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingresa tu RUT"
              value={rut}
              onChange={(e) => setRut(e.target.value)}
              isInvalid={rutError}
            />
            <Form.Control.Feedback type="invalid">{rutError}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="clave">
            <Form.Label>Clave</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ingresa tu clave"
              value={clave}
              onChange={(e) => setClave(e.target.value)}
              isInvalid={claveError}
            />
            <Form.Control.Feedback type="invalid">{claveError}</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleLogin}>
          Iniciar Sesión
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SecondUserLoginModal;
