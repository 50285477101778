import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function LoginModal({ show, onHide, isAuthenticated, setIsAuthenticated }) {
  const [rut, setRut] = useState('');
  const [clave, setClave] = useState('');
  const [rutError, setRutError] = useState('');
  const [claveError, setClaveError] = useState('');
  const navigate = useNavigate();

  const validateForm = () => {
    let isValid = true;

    // Validar el RUT
    //if (!rut.match(/^\d{7,8}-[\d|kK]$/)) {
      
    //}

    // Validar la clave
    //if (!clave.match(/^\d{4}$/)) {

    //}

    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // Aquí puedes enviar los datos del formulario
      console.log('RUT:', rut);
      console.log('Clave:', clave);
      console.log('Autenticación exitosa'); // Agrega este console.log para verificar si se llega correctamente aquí
      onHide(); // Cerrar el modal
      setIsAuthenticated(true); // Actualizar el estado de autenticación a true
      navigate('/ficha-clinica'); // Redirigir a la página de ficha clínica
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Ingresar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="rut">
            <Form.Label>RUT</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingresa tu RUT"
              value={rut}
              onChange={(e) => setRut(e.target.value)}
              isInvalid={rutError}
            />
            <Form.Control.Feedback type="invalid">{rutError}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="clave">
            <Form.Label>Clave</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ingresa tu clave"
              value={clave}
              onChange={(e) => setClave(e.target.value)}
              isInvalid={claveError}
            />
            <Form.Control.Feedback type="invalid">{claveError}</Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" type="submit">
            Ver Ficha
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;
