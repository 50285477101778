import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

function ColumnCard({ title, text, imageSrc, cardSize, imageSize }) {
  // Determina las clases de tamaño de Bootstrap basadas en las propiedades 'cardSize' e 'imageSize'
  const cardSizeClass = cardSize === 'small' ? 'col-sm-6 col-md-4' : 'col-sm-12 col-md-6';
  const imageSizeClass = imageSize === 'small' ? 'img-fluid rounded-start' : 'img-fluid';

  return (
    <div className="d-flex justify-content-center my-5"> {/* Añade margen superior e inferior */}
      <Card className={`rounded-3 shadow-sm border ${cardSizeClass}`}>
        <Card.Body>
          <Row>
            <Col>
              <Card.Title className="text-center mb-4" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>{title}</Card.Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={imageSrc} className={`${imageSizeClass} shadow-sm`} alt="Card" style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Card.Text className="text-justify mt-3" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>{text}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ColumnCard;
