import React from 'react';
import './../styles/HorasMedicas.css';

const dummyData = {
  "2024-05-18": [
    { time: '09:00', patient: 'Juan Pérez', rut: '12345678-9', age: 30, specialty: 'Consulta General', doctor: 'Dr. Gómez' },
    { time: '09:30', patient: 'Ana Torres', rut: '23456789-0', age: 28, specialty: 'Consulta General', doctor: 'Dr. Gómez' },
    { time: '10:00', patient: 'Pedro López', rut: '34567890-1', age: 40, specialty: 'Examen de Sangre', doctor: 'Dra. López' },
    { time: '10:30', patient: 'María Gómez', rut: '98765432-1', age: 45, specialty: 'Examen de Sangre', doctor: 'Dra. López' },
    { time: '11:00', patient: 'Carlos Sánchez', rut: '12378945-6', age: 50, specialty: 'Consulta Dermatología', doctor: 'Dr. Martínez' },
    { time: '11:30', patient: 'Laura Fernández', rut: '45678901-2', age: 35, specialty: 'Consulta Dermatología', doctor: 'Dr. Martínez' },
    { time: '12:00', patient: 'José Ramírez', rut: '56789012-3', age: 60, specialty: 'Consulta General', doctor: 'Dr. Gómez' },
    { time: '12:30', patient: 'Elena Morales', rut: '67890123-4', age: 50, specialty: 'Examen de Sangre', doctor: 'Dra. López' },
  ],
  "2024-05-19": [
    { time: '10:00', patient: 'Carlos Sánchez', rut: '12378945-6', age: 50, specialty: 'Consulta Dermatología', doctor: 'Dr. Martínez' },
  ],
  // Agrega más datos de prueba según sea necesario
};

const HorasMedicas = ({ date }) => {
  const activities = dummyData[date] || [];

  return (
    <div className="horas-medicas-container">
      {activities.length > 0 ? (
        <table className="activities-table">
          <thead>
            <tr>
              <th>Hora</th>
              <th>Paciente</th>
              <th>RUT</th>
              <th>Edad</th>
              <th>Especialidad</th>
              <th>Doctor</th>
            </tr>
          </thead>
          <tbody>
            {activities.map((activity, index) => (
              <tr key={index}>
                <td>{activity.time}</td>
                <td>{activity.patient}</td>
                <td>{activity.rut}</td>
                <td>{activity.age}</td>
                <td>{activity.specialty}</td>
                <td>{activity.doctor}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No hay actividades médicas para esta fecha.</p>
      )}
    </div>
  );
};

export default HorasMedicas;
