import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import '../styles/customcard.css'; // Importa los estilos CSS

function CustomCardReverse({ title, text, imageSrc, size }) {
  // Determina la clase de tamaño de Bootstrap basada en la propiedad 'size'
  const cardSizeClass = size === 'small' ? 'col-sm-6 col-md-6' : 'col-sm-12 col-md-6'; // Modifica 'col-md-6'

  return (
    <div className="d-flex justify-content-center my-4"> {/* Añade margen superior e inferior */}
      <Card className={`rounded-3 border ${cardSizeClass}`} style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }}>
        <Card.Body>
          <Row>
            <Col xs={12} md={6} className="d-flex justify-content-center justify-content-md-end align-items-center"> {/* Columna de la imagen */}
              <img src={imageSrc} className="img-fluid rounded-start" alt="Card" />
            </Col>
            <Col xs={12} md={6} className="text-center text-md-start"> {/* Columna del título y texto */}
              <div className="d-flex flex-column h-100 justify-content-center">
                <Card.Title>{title}</Card.Title>
                <Card.Text style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>{text}</Card.Text>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CustomCardReverse;
