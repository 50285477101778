import React from 'react';
import heroImage from './../images/horizontal 1.jpg'; // Importa la imagen utilizando import
import logoSVG from './../images/MW WHITE.svg'; // Importa tu archivo SVG
import './../styles/HeroSection.css'; // Importa los estilos CSS del componente
import misaludportable from '../images/mi_salud_portable.png'
function HeroSection() {
  return (
    <div className="hero-section">
      {/* Contenedor para la imagen y el logo */}
      <div className="hero-image-container">
        {/* Imagen de fondo */}
        <img
          className="hero-image"
          src={heroImage}
          alt="Imagen de MedWise"
        />
        {/* Logo superpuesto */}
        <img
          className="logo"
          src={misaludportable}
          alt="Logo"
        />
      </div>
      {/* Título */}
      <h1 className="hero-title">Descubre el Futuro de la Medicina con Mi Salud Portable</h1>
    </div>
  );
}

export default HeroSection;
