import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import Estadisticas from '../components/estadisticas';
import CalendarComponent from '../components/CalendarComponent';
import HorasMedicas from '../components/HorasMedicas';
import '../styles/dashboardpage.css';
import PatientList from '../components/patientlist';

const Dashboard = () => {
  const [selectedComponent, setSelectedComponent] = useState('Estadisticas');

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case 'Estadisticas':
        return <Estadisticas />;
      case 'Horas Medicas':
        return <CalendarComponent />;
      case 'Pacientes':
        return <PatientList></PatientList> ;
      default:
        return <Estadisticas />;
    }
  };

  return (
    <Container fluid className="app-container">
      <Row>
        <Col xs={12} className="p-0">
          <Header />
        </Col>
      </Row>
      <Row>
        <Col xs={2} className="sidebar-container p-0">
          <Sidebar setSelectedComponent={setSelectedComponent} />
        </Col>
        <Col xs={10} className="p-0">
          <div className="content-container">
            {renderSelectedComponent()}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
