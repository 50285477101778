import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './pages/landing';
import FichaClinicaPage from './pages/FichaClinicaPage';
import AdminPage from './pages/AdminPage';
import Dashboard from './pages/Dashboard';
import HorasMedicas from './components/HorasMedicas';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/ficha-clinica" element={isAuthenticated ? <FichaClinicaPage /> : <Navigate to="/" />} />
        <Route path="/admin-page" element={isAuthenticated ? <AdminPage /> : <Navigate to="/" />} />
        <Route path="/Data-analisis" element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />} />
        <Route path="/horas-medicas/:date" element={<HorasMedicas />} />

      </Routes>
    </Router>
  );
}

export default App;
