import React, { useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import LoginModal from './loginModal';
import SecondUserLoginModal from './secondmodal';

function ColumnCardbottom({ title, text, imageSrc, cardSize, imageSize, setIsAuthenticated }) {
  const [showModal, setShowModal] = useState(false);
  const [showSecondUserModal, setShowSecondUserModal] = useState(false);

  const handleVerFichaClinica = () => {
    setShowModal(true);
  };

  const handleAdminSalud = () => {
    setShowSecondUserModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseSecondUserModal = () => {
    setShowSecondUserModal(false);
  };

  return (
    <div className="d-flex justify-content-center my-5"> {/* Añade margen superior e inferior */}
      <Card className={`rounded-3 shadow-sm border ${cardSize}`}>
        <Card.Body>
          <Row>
            <Col>
              <Card.Title className="text-center mb-4">{title}</Card.Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={imageSrc} className={`img-fluid ${imageSize} shadow-sm`} alt="Card" /> {/* Ajuste de clase para la imagen */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card.Text className="text-justify mt-3">{text}</Card.Text>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3">
              <Button variant="primary" className="me-2" onClick={handleVerFichaClinica}>Ver Ficha Clínica</Button>
              <Button variant="primary" onClick={handleAdminSalud}>Admin Salud</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <LoginModal show={showModal} onHide={handleCloseModal} setIsAuthenticated={setIsAuthenticated} />
      <SecondUserLoginModal show={showSecondUserModal} onHide={handleCloseSecondUserModal} setIsAuthenticated={setIsAuthenticated} />
    </div>
  );
}

export default ColumnCardbottom;
